import { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { SORT_OPTIONS, SORT_ORDER } from "../utils/constants";
import { DEFAULT_PRICE_RANGE } from "../utils/constants";
// Create a context to hold the state and dispatch function
const FilterContext = createContext();

/*
    Reducer function to manage state changes
    it takes the current state and an action 
    and returns a new state based on the action type
*/
const filterReducer = (state, action) => {
    switch (action.type) {
        case "SET_SELECTED_BRANDS":
            return {
                ...state,
                selectedBrands: action.payload,
                page: 1,
            };
        //? Reset priceRange because changes in main categories triggers changes in priceRange

        case "SET_SELECTED_MAIN_CATEGORIES":
            //reset priceRange to default because main categories refetch brands,categories which refetch priceRange
            console.log("SET_SELECTED_MAIN_CATEGORIES");
            return {
                ...state,
                selectedMainCategories: action.payload,
                selectedSubCategories: [],
                selectedBrands: [],
                page: 1,
                // ! Don't Remove it
                // ? This part of the fix for [Double Rendering Loading Component]
                priceRange: DEFAULT_PRICE_RANGE,
                searchBrand: "",
            };
        case "SET_SELECTED_SUB_CATEGORIES":
            console.log("SET_SELECTED_SUB_CATEGORIES");

            return {
                ...state,
                selectedSubCategories: action.payload,
                page: 1,
                searchBrand: "",
            };

        case "SET_SELECTED_STORES":
            return {
                ...state,
                selectedStores: action.payload,
                page: 1,
            };
        case "SET_PRICE_RANGE":
            return { ...state, priceRange: action.payload, page: 1 };
        case "SET_API_PRICE_RANGE":
            return { ...state, apiPriceRange: action.payload };
        case "SET_SEARCH":
            return {
                ...state,
                search: action.payload,
                page: 1,
            };
        case "SET_SEARCH_BRAND":
            return {
                ...state,
                searchBrand: action.payload,
            };
        case "SET_PAGE":
            return {
                ...state,
                page: action.payload,
            };
        case "SET_SORT_BY":
            return {
                ...state,
                sortBy: action.payload,
            };
        case "SET_ORDER":
            return {
                ...state,
                order: action.payload,
            };
        case "SET_LANGUAGE":
            return {
                ...state,
                lang: action.payload,
            };
        case "SET_CONDITION":
            return {
                ...state,
                condition: action.payload,
            };
        case "RESET_SEARCH":
            return { ...state, search: "", priceRange: DEFAULT_PRICE_RANGE };
        case "RESET_FILTERS":
            return {
                ...state,
                selectedBrands: [],
                selectedMainCategories: [0],
                selectedSubCategories: [],
                selectedStores: [],
                page: 1,
                sortBy: SORT_OPTIONS.RELEVANCE,
                order: SORT_ORDER.DESC,
                condition: [],
                searchBrand: "",
            };
        default:
            return state;
    }
};

// initialize the state object
const initialState = {
    selectedBrands: [],
    selectedMainCategories: [0],
    selectedSubCategories: [],
    selectedStores: [],
    // TODO: get the initial value from the API
    apiPriceRange: DEFAULT_PRICE_RANGE,
    priceRange: DEFAULT_PRICE_RANGE,
    // defaultPriceRange: [0, 100000], // Add this line to store the default price range
    search: "",
    page: 1,
    sortBy: SORT_OPTIONS.RELEVANCE,
    order: SORT_ORDER.DESC,
    lang: "en",
    condition: [],
    searchBrand: "",
};

// Provider component to provide state and dispatch function to children
export const FilterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(filterReducer, initialState);

    return (
        <FilterContext.Provider value={{ state, dispatch }}>
            {children}
        </FilterContext.Provider>
    );
};

FilterProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Custom hook to access state and dispatch function from context
// eslint-disable-next-line react-refresh/only-export-components
export const useFilter = () => useContext(FilterContext);

