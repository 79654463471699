import { useTranslation } from "react-i18next";
import imageNotFound from "../../assets/imageNotFound.png";
import PropTypes from "prop-types";
import { SaudiRiyal } from "../productsList/SaudiRiyal";

const AIProductCard = ({
    image,
    title,
    price,
    oldPrice,
    discount,
    productStoreUrl,
    url,
    // currency_ar,
    // currency_en,
    store_name,
    store_name_en,
    query_lang,
}) => {
    const { t } = useTranslation();
    // const isArabic = (text) =>
    //     /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/.test(text);

    // const isTitleArabic = isArabic(title);
    const isTitleArabic = query_lang == "Arabic" ? true : false;

    return (
        <div className="product relative my-4 flex max-w-md items-center rounded-lg border bg-white">
            {/* Discount Badge */}
            {discount > 0 && (
                <div
                    className={`absolute ${isTitleArabic ? "ar right-0 top-0" : "en left-0 top-0"} discount discount-div bg-orange-500 p-1 text-[10px] font-bold text-white`}
                >
                    {discount}%
                </div>
            )}

            {/* Product Image */}
            <div className="flex w-1/3 p-2">
                <img
                    src={image}
                    style={{ width: "62px", height: "62px" }}
                    alt=""
                    className="h-auto w-full cursor-pointer rounded-lg object-cover"
                    onClick={() => window.open(url, "_blank")}
                    onError={(e) => {
                        e.currentTarget.src = imageNotFound;
                        e.currentTarget.classList.add("w-full", "h-auto");
                    }}
                />
            </div>

            {/* Product Info */}
            <div className="w-3/4">
                <div className="mt-2 flex items-center">
                    <div className={`banner ${isTitleArabic ? "" : "en"}`}>
                        <img
                            src={`/image/store-images/${store_name_en}.webp`}
                            style={{
                                width: "24px",
                                height: "24px",
                                display: "block",
                            }}
                            alt=""
                            className={`m-0 h-auto w-full rounded-lg object-cover p-0 ${isTitleArabic ? "" : "en"}`}
                            onClick={() => window.open(url, "_blank")}
                            onError={(e) => {
                                e.currentTarget.src = imageNotFound;
                                e.currentTarget.classList.add(
                                    "w-full",
                                    "h-auto",
                                );
                            }}
                        />
                        <span
                            className={`text ${isTitleArabic ? "" : "en"}`}
                        >
                            {store_name}
                        </span>
                    </div>
                </div>
                <div
                    className={`flex flex-col ${isTitleArabic ? "mx-2 my-2" : "mx-2 my-2"}`}
                >
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <h2
                            className={`text-xm font-body line-clamp-2 text-gray-900 underline ${isTitleArabic ? "text-right" : "text-left"}`}
                        >
                            {title}
                        </h2>
                    </a>

                    {/* Price Section */}
                    <div className="mt-4 flex items-center gap-2">
                        <p
                            className="font-heading flex items-center justify-center text-sm font-medium leading-normal text-body-font sm:text-lg md:text-base"
                            dir="ltr"
                            title={t("saudi_riyal")}
                        >
                            <SaudiRiyal className="mx-[0.125rem] inline-block h-3 w-3" />
                            <span className="text-sm font-bold">{price}</span>
                        </p>

                        {/* Old Price (Remains in-line) */}
                        {oldPrice && oldPrice !== price && (
                            <p
                                className="font-lato flex items-center justify-center text-xs font-normal leading-tight text-orange-500 line-through md:text-sm"
                                dir="ltr"
                                title={t("saudi_riyal")}
                            >
                                <SaudiRiyal
                                    color="#6b7280"
                                    className="mx-[0.125rem] inline-block h-[0.65rem] w-[0.65rem]"
                                />
                                <span className="text-xs text-gray-500">
                                    {oldPrice}
                                </span>
                            </p>
                        )}
                    </div>

                    {/* Buttons Section */}
                    <div className="mx-0 mt-4 flex w-full items-center">
                        {/* Buy from store */}
                        <button
                            className="mt-0 w-full rounded-sm border border-orange-500 px-2 py-1 text-xs text-primary-orange transition hover:bg-orange-500 hover:text-white"
                            onClick={() =>
                                window.open(productStoreUrl, "_blank")
                            }
                        >
                            {t("buy_from_store")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

AIProductCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    oldPrice: PropTypes.string,
    discount: PropTypes.number,
    productStoreUrl: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    // currency_ar: PropTypes.string.isRequired,
    // currency_en: PropTypes.string.isRequired,
    store_name: PropTypes.string.isRequired,
    store_name_en: PropTypes.string.isRequired,
    query_lang: PropTypes.string.isRequired,
};

export default AIProductCard;
